<template>
  <svg
    fill="none"
    height="9"
    viewBox="0 0 11 9"
    width="11"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 1.24444L9.42222 0L3.55556 5.86667L1.24444 3.55556L0 4.8L3.55556 8.35556L10.6667 1.24444Z"
      fill="currentColor"
    />
  </svg>
</template>
